
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeGoal",
  props: {
    url: {
      type: String,
    },
    to: {
      type: String,
    },
    goal: {
      type: String,
    },
    imageSrc: {
      type: String,
    },
  },
});
