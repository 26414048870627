import { RouteRecordRaw } from "vue-router";
import store from "@/store";
import { BIRTH_CONTROL_URL_BASE } from "./constants";

const routes: RouteRecordRaw[] = [
  {
    path: `/${BIRTH_CONTROL_URL_BASE}`,
    name: `${BIRTH_CONTROL_URL_BASE}`,
    component: () => import("../views/BirthControl/loading.vue"),
  },
  {
    path: `/${BIRTH_CONTROL_URL_BASE}/introduction`,
    component: () => import("../views/BirthControl/introduction.vue"),
  },
  {
    path: `/${BIRTH_CONTROL_URL_BASE}/recommendation`,
    component: () => import("../views/BirthControl/recommend.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.startedQuizFromBeginning) {
        return next();
      }
      next({ name: `${BIRTH_CONTROL_URL_BASE}` });
    },
  },
];

/**
 * BirthControl Questions
 */
for (const index of Array(12).keys()) {
  const step = index + 1;
  routes.push({
    path: `/${BIRTH_CONTROL_URL_BASE}/${step}`,
    name: `${BIRTH_CONTROL_URL_BASE}-${step}`,
    component: () => import(`../views/BirthControl/question-${step}.vue`),
    beforeEnter: (to, from, next) => {
      if (store.state.startedQuizFromBeginning) {
        return next();
      }
      next({ name: `${BIRTH_CONTROL_URL_BASE}` });
    },
  });
}

export default routes;
