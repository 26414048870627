import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import analytics from "@/utils/analytics";

import routesBirthControl from "./birthcontrol";
import routesEmergencyContraception from "./emergencycontraception";

let routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

routes = routes.concat(routesBirthControl);
routes = routes.concat(routesEmergencyContraception);

/**
 * Other Pages
 */
routes.push({
  path: "/:pathMatch(.*)*",
  name: "404",
  component: () => import(`../views/Error404.vue`),
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//  Global Guards
router.afterEach((to, from) => {
  analytics.page();
});

export default router;
