
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import SupportHelp from "../Support/Help.vue";

export default defineComponent({
  name: "Navbar",
  components: {
    SupportHelp,
  },
  setup() {
    const router = useRouter();
    return {
      goToHome: () => router.push("/"),
    };
  },
});
