
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import QuestionNext from "./Next.vue";

export default defineComponent({
  name: "Textarea",
  components: {
    QuestionNext,
  },
  props: {
    onAnswered: {
      type: Function,
      default: (value: string) => {
        console.error(`onAnswered not implemented: ${value}`);
      },
    },
    title: {
      type: String,
    },
    questionKey: {
      type: [Number, String],
      default: "non-null",
    },
  },
  setup(props) {
    const store = useStore();
    const currentAnswer = computed<string[]>(
      () => store.state.answers[props.questionKey]?.answer || []
    );

    const onChange = (e: Event) => {
      const value = (e.target as HTMLInputElement).value;
      store.commit("saveAnswer", {
        [props.questionKey]: {
          question: props.title,
          answer: !value || value.length === 0 ? "" : value,
        },
      });
    };

    const onNext = () => {
      store.commit("saveAnswer", {
        [props.questionKey]: {
          question: props.title,
          answer:
            !currentAnswer.value || currentAnswer.value.length === 0
              ? ""
              : currentAnswer.value,
        },
      });
      props.onAnswered(currentAnswer.value);
    };

    return {
      currentAnswer,

      onChange,
      onNext,
    };
  },
});
