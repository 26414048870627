import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    class: "\n      text-center\n      bg-accent\n      text-white\n      rounded-full\n      py-4\n      w-full\n      max-w-5xl\n      text-lg\n      font-bold\n      uppercase\n    ",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
  }, [
    _createVNode("span", { innerHTML: _ctx.text }, null, 8, ["innerHTML"])
  ]))
}