import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.to)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.to,
        class: "\n      cursor-pointer\n      bg-white\n      text-center\n      py-6\n      px-5\n      border-solid border-2\n      w-full\n      border-gray-100\n    "
      }, {
        default: _withCtx(() => [
          _createVNode("img", {
            class: "mx-auto h-14 rounded-full bg-white mb-2",
            src: _ctx.imageSrc,
            alt: _ctx.goal
          }, null, 8, ["src", "alt"]),
          _createVNode("span", null, _toDisplayString(_ctx.goal), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createBlock("a", {
        key: 1,
        href: _ctx.url,
        class: "\n      cursor-pointer\n      bg-white\n      text-center\n      py-6\n      px-5\n      border-solid border-2\n      w-full\n      border-gray-100\n    "
      }, [
        _createVNode("img", {
          class: "mx-auto h-14 rounded-full bg-white mb-2",
          src: _ctx.imageSrc,
          alt: _ctx.goal
        }, null, 8, ["src", "alt"]),
        _createVNode("span", null, _toDisplayString(_ctx.goal), 1)
      ], 8, ["href"]))
}