import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import "./registerServiceWorker";

import App from "./App.vue";
import { appDomain, sentry } from "./config/config";
import router from "./router";
import store from "./store";

import "./assets/style.css";

const app = createApp(App);

Sentry.init({
  app,
  dsn: sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", appDomain, /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.use(store).use(router).mount("#app");
