import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-4 md:gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Goal = _resolveComponent("Goal")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, null, {
    default: _withCtx(() => [
      _createVNode(_component_Header, {
        title: "<div class='text-center'>What should we explore?</div>",
        showBackButton: false
      }),
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Goal, {
          to: _ctx.BIRTH_CONTROL_URL_BASE,
          imageSrc: 
          require('../assets/healthicons/outline/family/contraceptive_voucher.svg')
        ,
          goal: "Birth Control"
        }, null, 8, ["to", "imageSrc"]),
        _createVNode(_component_Goal, {
          to: _ctx.EMERGENCY_CONTRACEPTION_URL_BASE,
          imageSrc: 
          require('../assets/healthicons/outline/family/oral_contraception_pillsx28.svg')
        ,
          goal: "Emergency Contraception"
        }, null, 8, ["to", "imageSrc"])
      ])
    ]),
    _: 1
  }))
}