
import { defineComponent } from "vue";

export default defineComponent({
  name: "QuestionNext",
  props: {
    text: {
      type: String,
      default: "Next&nbsp;&rarr;",
    },
    onNext: Function,
  },
  setup(props) {
    return {
      next: props.onNext,
    };
  },
});
