
import { defineComponent } from "vue";
import Container from "../components/Structure/Container.vue";
import { Header } from "../components/Questions";
import Goal from "../components/Home/Goal.vue";
import {
  BIRTH_CONTROL_URL_BASE,
  EMERGENCY_CONTRACEPTION_URL_BASE,
} from "../router/constants";

export default defineComponent({
  name: "Home",
  components: {
    Container,
    Goal,
    Header,
  },
  setup() {
    return {
      BIRTH_CONTROL_URL_BASE,
      EMERGENCY_CONTRACEPTION_URL_BASE,
    };
  },
});
