
import { computed, defineComponent, PropType } from "vue";
import { useStore } from "vuex";

import { Answer } from "@/types/store.d";

export default defineComponent({
  name: "SingleCheckbox",
  props: {
    options: {
      type: Array as PropType<
        {
          label: string;
          subtitle: string;
          image: string;
          value: string;
        }[]
      >,
    },
    onAnswered: {
      type: Function,
      default: (value: string) => {
        console.error(`onAnswered not implemented: ${value}`);
      },
    },
    title: {
      type: String,
    },
    questionKey: {
      type: [Number, String],
      default: "non-null",
    },
  },
  setup(props) {
    const store = useStore();
    const currentAnswer = computed<Answer>(
      () => store.state.answers[props.questionKey]?.answer
    );

    const onClick = (value: string) => {
      store.commit("saveAnswer", {
        [props.questionKey]: {
          question: props.title,
          answer: value,
        },
      });
      props.onAnswered();
    };

    return {
      onClick,
      currentAnswer,
    };
  },
});
