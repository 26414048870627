
import { onMounted, defineComponent } from "vue";
import { useStore } from "vuex";
import Navbar from "./components/Structure/Navbar.vue";

export default defineComponent({
  name: "Home",
  components: {
    Navbar,
  },
  setup() {
    const store = useStore();

    /**
     * Before unmounted lifecycle
     *
     * @todo consider adding logic to check for startedQuizFromBeginning.
     * For now we will consistently block the user
     */
    onMounted(() => {
      window.addEventListener("beforeunload", function (event) {
        if (store.state.startedQuizFromBeginning) {
          event.preventDefault();
          const message =
            "You have not completed the evaluation. Are you sure you want to leave?";

          //  Chrome requires this message to be set
          event.returnValue = message;
          return message;
        }
      });
    });
  },
});
