
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "vuex";
import { CheckIcon } from "@heroicons/vue/outline";
import QuestionNext from "./Next.vue";

export default defineComponent({
  name: "MultiCheckbox",
  components: {
    QuestionNext,
    CheckIcon,
  },
  props: {
    onAnswered: {
      type: Function,
      default: (value: string) => {
        console.error(`onAnswered not implemented: ${value}`);
      },
    },
    options: {
      type: Array as PropType<
        {
          label: string;
          subtitle: string;
          image: string;
          value: string;
        }[]
      >,
    },
    title: {
      type: String,
    },
    questionKey: {
      type: [Number, String],
      default: "non-null",
    },
  },
  setup(props) {
    const store = useStore();
    const errorMessage = ref<string>();
    const currentAnswer = computed<string[]>(
      () => store.state.answers[props.questionKey]?.answer || []
    );

    const onClick = (value: string) => {
      if (currentAnswer.value.indexOf(value) === -1) {
        onSelected(value);
      } else {
        onUnselected(value);
      }
    };

    const onSelected = (value: string) => {
      if (value === "NIL") {
        store.commit("saveAnswer", {
          [props.questionKey]: {
            question: props.title,
            answer: [value],
          },
        });
      } else {
        const set = new Set(currentAnswer.value);
        set.delete("NIL");
        store.commit("saveAnswer", {
          [props.questionKey]: {
            question: props.title,
            answer: Array.from(set.add(value)),
          },
        });
      }
    };
    const onUnselected = (value: string) => {
      store.commit("saveAnswer", {
        [props.questionKey]: {
          question: props.title,
          answer: currentAnswer.value.filter(
            (currentValue) => currentValue !== value
          ),
        },
      });
    };

    const onNext = () => {
      if (!currentAnswer.value || currentAnswer.value.length === 0) {
        errorMessage.value =
          "You need to answer this question before proceeding.";
      } else {
        props.onAnswered(currentAnswer.value);
      }
    };

    return {
      currentAnswer,
      errorMessage,
      onClick,
      onNext,
    };
  },
});
