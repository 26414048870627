
import { defineComponent } from "vue";
import { intercomAppId } from "@/config/config";

export default defineComponent({
  name: "SupportHelp",
  setup() {
    const Intercom = window.Intercom;
    Intercom("boot", {
      app_id: intercomAppId,
      hide_default_launcher: true,
      custom_launcher_selector: "#intercom-launcher",
    });
  },
});
