
import { defineComponent } from "vue";
import { ArrowLeftIcon } from "@heroicons/vue/solid";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Header",
  components: {
    ArrowLeftIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    subtitle: {
      type: String,
    },
  },
  setup() {
    const router = useRouter();

    return {
      back: () => router.back(),
    };
  },
});
