import { RouteRecordRaw } from "vue-router";
import store from "@/store";
import { EMERGENCY_CONTRACEPTION_URL_BASE } from "./constants";

const routes: RouteRecordRaw[] = [
  {
    path: `/${EMERGENCY_CONTRACEPTION_URL_BASE}`,
    name: `${EMERGENCY_CONTRACEPTION_URL_BASE}`,
    component: () => import("../views/EmergencyContraception/loading.vue"),
  },
  {
    path: `/${EMERGENCY_CONTRACEPTION_URL_BASE}/introduction`,
    component: () => import("../views/EmergencyContraception/introduction.vue"),
  },
  {
    path: `/${EMERGENCY_CONTRACEPTION_URL_BASE}/recommendation`,
    component: () => import("../views/EmergencyContraception/recommend.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.startedQuizFromBeginning) {
        return next();
      }
      next({ name: `${EMERGENCY_CONTRACEPTION_URL_BASE}` });
    },
  },
];

/**
 * EmergencyContraception Questions
 */
for (const index of Array(7).keys()) {
  const step = index + 1;
  routes.push({
    path: `/${EMERGENCY_CONTRACEPTION_URL_BASE}/${step}`,
    name: `${EMERGENCY_CONTRACEPTION_URL_BASE}-${step}`,
    component: () =>
      import(`../views/EmergencyContraception/question-${step}.vue`),
    beforeEnter: (to, from, next) => {
      if (store.state.startedQuizFromBeginning) {
        return next();
      }
      next({ name: `${EMERGENCY_CONTRACEPTION_URL_BASE}` });
    },
  });
}

export default routes;
