import { createStore, MutationTree } from "vuex";

import { Answer, State } from "@/types/store.d";

/**
 * Mutations
 */
export const mutations: MutationTree<State> = {
  startQuiz(state: State) {
    state.startedQuizFromBeginning = true;
  },
  endQuiz(state: State) {
    state.startedQuizFromBeginning = false;
  },
  saveAnswer(state: State, payload: Record<string, Answer>) {
    state.answers = {
      ...state.answers,
      ...payload,
    };
  },
};

export default createStore({
  state: {
    startedQuizFromBeginning: false,
    answers: {},
  },
  mutations,
  actions: {},
  modules: {},
});
